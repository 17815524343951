import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

/**
 * Composant permettant d'afficher un evénements en évidence
 * @returns {JSX.Element}
 * @constructor
 */
export default class EventHighlight extends React.Component {
  render() {
    const { event } = this.props

    if(event !== null){
      let image = getImage(event.staticCover)

      return (
        <div key={event.id} className={"event highlight"}>
          <h2 className={"event-header"}>Prochain événement</h2>
          <div className={"event-content"}>
            <div className={"event-content--left"}>
              <h4 className={"event-content--left-title"}>{event.name}</h4>
              <div className={"event-content--left-date"}>{event.start_time}</div>
              <div className={"event-content--left-desc"}>{event.description.substring(0, 150) + " ..."}</div>
              <a className={"event-content--left-more"} href={"https://www.facebook.com/events/"+event.id}>En savoir plus</a>
            </div>
            <GatsbyImage
              className={"event-content--right"}
              image={image}
              alt="Cat taking up an entire chair"
              placeholder={"none"}
            />
          </div>
        </div>
      )
    }else{
      return('')
    }
  }
}
