import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

/**
 * Composant permettant d'afficher un futur événement
 * @returns {JSX.Element}
 * @constructor
 */
export default class EventUpcoming extends React.Component {
  render() {
    const { events } = this.props
    const eventsUpcoming = (typeof events.edges !== "undefined") ? events.edges.slice(1) : []

    if(eventsUpcoming.length > 0){
      return (
        eventsUpcoming.map(({node}) => {
          const image = getImage(node.staticCover)

          return (
            <div key={node.id} className={"event upcoming"}>
              <div className={"event-content"}>
                <div className={"event-content--left"}>
                  <h4 className={"event-content--left-title"}>{node.name}</h4>
                  <div className={"event-content--left-date"}>{node.start_time}</div>
                  <div className={"event-content--left-desc"}>{node.description.substring(0, 150) + " ..."}</div>
                  <a className={"event-content--left-more"} href={"https://www.facebook.com/events/"+node.id}>En savoir plus</a>
                </div>
                <GatsbyImage
                  className={"event-content--right"}
                  image={image}
                  alt="Cat taking up an entire chair"
                  placeholder={"none"}
                />
              </div>
            </div>
          )
        })
      )
    }else{
      return(
        <div className={'no-events-upcoming'}>
          Aucun événements à venir&nbsp;!
        </div>
      )
    }
  }
}
