import React from "react";
import EventUpcoming from "src/components/event/event-upcoming";
import { v4 as uuidv4 } from 'uuid';

/**
 * Composant permettant d'afficher la iste des futurs événements
 * @returns {JSX.Element}
 * @constructor
 */
export default class EventsUpcoming extends React.Component {
  render() {
    const { events } = this.props

    return (
      <div key={uuidv4()} className={"events-upcoming"}>
        <div className={"events-upcoming-header"}>
          <h4 className={"events-upcoming-header-title"}>Événement(s) à venir</h4>
          <a className={"events-upcoming-header-more"} href={this.props.urlFacebook + "events"} title={"Accéder à l'esemble des événements sur la page facebook"}>Tous les événements</a>
        </div>
        <EventUpcoming events={events} />
      </div>
    )
  }
}
