import * as React from "react"
import {graphql} from "gatsby";
import MainLayout from "src/components/layoutmain";
import LayoutCentered from "src/components/layoutcentered";
import EventHighlight from "src/components/event/event-highlight";
import EventsUpcoming from "src/components/event/events-upcoming";

/**
 * Page Evenements
 * @returns {JSX.Element}
 * @constructor
 */
export default function Evenements({ data }) {
    const pageProps = {
        title: "Le live bar - Les événements",
        menuPageTitle: "Les événements",
        description:
            "Concert, culture, art, dessiner, exposer, jouer, se poser, regarder, délirer, discuter et chanter, en bref s'exprimer",
        className: "evenements",
    };
    const events = (typeof data.allFacebookGraphApiJson !== "undefined") ? data.allFacebookGraphApiJson : []
    const firstEvent = (typeof data.allFacebookGraphApiJson !== "undefined" && data.allFacebookGraphApiJson.totalCount > 0) ? data.allFacebookGraphApiJson.edges[0].node : null;

    return (
        <MainLayout pageProps={pageProps}>
            <LayoutCentered>
                <div className={"evenements-container"}>
                    <div className={"evenements-container-header"}>
                        <h3>
                            <span>
                                <span className={"title"}>Le live bar</span> veut vous faire vivre des expériences {''}
                                <span className={"underline height-small width-medium"}>inoubliables</span> en organisant {''}
                                un large choix d'événements culturels.
                            </span>
                        </h3>
                    </div>
                    <EventHighlight event={firstEvent}/>
                    <EventsUpcoming events={events} urlFacebook={data.site.siteMetadata.urlFacebook}/>
                </div>
            </LayoutCentered>
        </MainLayout>
    )
}

// export const query = graphql`
//   query{
//     allFacebookGraphApiJson(
//         sort: {fields: end_time, order: DESC},
//         filter: { isFuture: { eq: true } }
//       ) {
//         totalCount
//         edges {
//           node {
//             id
//             name
//             end_time(formatString: "ddd, DD MMM YYYY", locale: "fr")
//             cover {
//               source
//             }
//             description
//           }
//         }
//       }
//   }
// `

// export const query = graphql`
//   query{
//     site {
//       siteMetadata {
//         urlFacebook
//       }
//     },
//     allFacebookGraphApiJson(
//         sort: {fields: start_time, order: ASC}
//         filter: {isFuture: {eq: true}}
//       ) {
//         totalCount
//         edges {
//           node {
//             id
//             name
//             start_time(formatString: "ddd, DD MMM YYYY", locale: "fr")
//             cover {
//               source
//             }
//             description
//             staticCover {
//               childImageSharp {
//                 gatsbyImageData(width: 200, height: 200)
//               }
//             }
//           }
//         }
//       }
//   }
// `

// TODO : Disabled allFacebookGraphApiJson
export const query = graphql`
  query{
    site {
      siteMetadata {
        urlFacebook
      }
    },
  }
`